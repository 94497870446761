import { Text } from '@components'
import {
  CheckboxInput,
  FormattedCurrency,
  LoaderButton,
  StatusBox,
  TextInput,
} from '@newComponents'
import { getVestingDuration } from '@utils'
import { FormEvent } from 'react'
import { useForm } from 'react-hook-form'
import { VscLinkExternal } from 'react-icons/vsc'
import {
  Distributor,
  EventDocument,
  VestingSchedule,
} from 'tokensoft-shared-types'

interface ClaimDocumentsStepProps {
  eventDocuments: EventDocument[]
  distributor: Distributor
  allocationAmount: CurrencyValue
  vestingSchedule?: VestingSchedule
  beneficiaryWallet: EvmAddress
  ambassadorName: Maybe<string>
  isPending: boolean
  onSubmit: () => Promise<void>
  isSignatureRequired: boolean
}

export const ClaimDocumentsStep = ({
  eventDocuments,
  allocationAmount,
  vestingSchedule,
  beneficiaryWallet,
  ambassadorName,
  isPending,
  onSubmit,
  isSignatureRequired,
}: ClaimDocumentsStepProps) => {
  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      agreementTerms: false,
      fullNameInput: '',
      signature: '',
    },
  })

  const fullName = ambassadorName ?? watch('fullNameInput')

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    void onSubmit()
  }

  return (
    <div className='flex flex-col gap-6 text-[#344054]'>
      <Text className='text-xl font-bold'>Sign Documents</Text>
      <div className='flex flex-col gap-6 p-4 rounded border border-[#d0d5dd]'>
        <div className='flex justify-between'>
          <div className='flex-1 text-start'>
            <div className='text-md font-medium'>Total Allocation</div>
            <span className='text-2xl font-bold'>
              <FormattedCurrency currencyValue={allocationAmount} />
            </span>
          </div>
          {vestingSchedule && (
            <div className='flex-1 text-start'>
              <div className='text-md font-medium'>Vesting Period</div>
              <div className='text-2xl font-bold'>
                {getVestingDuration(vestingSchedule)}
              </div>
            </div>
          )}
        </div>
        <div className='w-full h-[1px] bg-[#d0d5dd]' />
        <div className='mb-2'>
          <div className='text-sm mb-1 font-medium'>Beneficiary Wallet</div>
          <div className='font-mono font-light'>{beneficiaryWallet}</div>
        </div>
      </div>

      <StatusBox
        status='info'
        text='Please review the following documents to make sure you understand the terms of your agreement and make sure the details are correct.'
      />

      <form onSubmit={handleSubmit}>
        <div className='mb-6'>
          {eventDocuments.map((doc) => (
            <a
              key={doc.id}
              className='flex items-center font-medium gap-2 text-primary-medium mb-2'
              href={doc.uri}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Text>{doc.name}</Text>
              <VscLinkExternal />
            </a>
          ))}
          <CheckboxInput
            className='p-1 text-sm'
            id='agreementTerms'
            label={`By checking this box and pressing the "${
              isSignatureRequired ? 'Sign Documents' : 'Submit'
            }" button, I agree to comply with and be bound by all terms of the Ambassador's Agreement.`}
            register={register}
            validate={(value) =>
              value === true || 'You must agree to the terms'
            }
          />
        </div>

        <div className='flex justify-between mt-6 mb-4'>
          {ambassadorName !== null && (
            <div className='flex-1'>
              <div className='text-sm font-semibold'>Ambassador's Name</div>
              <div className='text-base'>{`${ambassadorName}`}</div>
            </div>
          )}
          <div className='flex-1'>
            <div className='text-sm font-semibold'>Date</div>
            <div className='text-base'>{new Date().toLocaleDateString()}</div>
          </div>
        </div>

        {isSignatureRequired && (
          <div className='mb-6 flex flex-col gap-6'>
            {ambassadorName === null && (
              <TextInput
                id='fullNameInput'
                label='Your Full Name'
                placeholder='Enter your full name'
                register={register}
                validate={(value) =>
                  (value && value.trim().length > 0) || 'Full name is required'
                }
                error={errors.fullNameInput?.message}
              />
            )}
            <TextInput
              id='signature'
              label='Signature'
              placeholder='Type your full name to sign'
              register={register}
              validate={(value) =>
                value === fullName || 'Signature must match your full name'
              }
              error={errors.signature?.message}
            />
          </div>
        )}

        <LoaderButton
          className='w-full rounded-full'
          type='submit'
          disabled={!isValid}
          isLoading={isPending}
          loadingText={isSignatureRequired ? 'Signing...' : 'Submitting...'}
          text={isSignatureRequired ? 'Sign Documents' : 'Submit'}
        />
      </form>
    </div>
  )
}
