import { useMyEventUser, usePostSignedOrReviewedDocuments } from '@apiServices'
import { LoadingIndicator, Text } from '@components'
import { useAccount, useToast } from '@contexts'
import { useEventDocuments, useTSAddress } from '@hooks'
import { AnimatedStepper, useStepper } from '@newComponents'
import * as Sentry from '@sentry/react'
import { userHasSignedOrReviewedAllDocuments } from '@utils'
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import {
  Distributor,
  DistributorMerkleTreeLeaf,
  Erc20Token,
  TSEvent,
} from 'tokensoft-shared-types'
import { TransactionReceipt } from 'viem'
import { ClaimDocumentsStep } from './claim-documents-step'
import { ClaimStep } from './claim-step'

interface ClaimFlow {
  event: TSEvent
  distributor: Distributor
  chainId: number
  distributorAddress: EvmAddress
  token: Erc20Token
  claimAmount: BigNumber
  merkleLeaf: DistributorMerkleTreeLeaf
  onClaimSuccess: (receipt: TransactionReceipt) => void
  onFinished: () => void
}

export const ClaimFlow = ({
  event,
  distributor,
  chainId,
  distributorAddress,
  token,
  claimAmount,
  merkleLeaf,
  onClaimSuccess,
  onFinished,
}: ClaimFlow) => {
  const {
    data: { eventDocuments, eventUserDocumentsStatus },
    isPending: isPendingEventDocuments,
    error: eventDocumentsError,
  } = useEventDocuments(event.id)

  const { account } = useAccount()
  const { showErrorToast } = useToast()
  // Fall back to null if firstName && lastName not available
  const userFirstName = account?.firstName ?? ''
  const userLastName = account?.lastName ?? ''
  const userFullName =
    userFirstName && userLastName ? `${userFirstName} ${userLastName}` : null

  const isDocumentActionRequired = useMemo(() => {
    if (eventDocuments === null || eventUserDocumentsStatus === null) {
      return false
    }

    if (eventDocuments.length === 0) {
      return true
    }

    return !userHasSignedOrReviewedAllDocuments(
      eventDocuments,
      eventUserDocumentsStatus,
    )
  }, [eventDocuments, eventUserDocumentsStatus])

  const { address } = useTSAddress()

  const {
    data: eventUser,
    isPending: isPendingEventUser,
    error: eventUserError,
  } = useMyEventUser(event.id)

  const {
    mutateAsync: postDocumentsSignedOrReviewed,
    isPending: isPendingPostDocumentSignOrAgree,
  } = usePostSignedOrReviewedDocuments(event.id)

  const isNextDisabled = (_stepIndex: number): boolean => {
    return false
  }

  const totalSteps = isDocumentActionRequired ? 2 : 1
  const startsAtIndex = isDocumentActionRequired ? 0 : 1

  const { step, nextHidden, goToNextStep, goToPreviousStep } = useStepper({
    totalSteps,
    startsAtIndex,
  })

  if (isPendingEventDocuments || isPendingEventUser) {
    return (
      <div className='flex items-center justify-center p-8'>
        <LoadingIndicator />
      </div>
    )
  }

  if (eventDocumentsError) {
    return (
      <div className='flex flex-col items-center justify-center p-8 gap-4'>
        <Text className='text-red-600'>
          Error loading documents: {eventDocumentsError.message}
        </Text>
      </div>
    )
  }

  if (eventUserError) {
    return (
      <div className='flex flex-col items-center justify-center p-8 gap-4'>
        <Text className='text-red-600'>
          Error loading user data: {eventUserError.message}
        </Text>
      </div>
    )
  }

  const handleDocumentSigning = async () => {
    if (!eventDocuments) return

    try {
      const eventDocumentIds = eventDocuments.map((doc) => doc.id)
      await postDocumentsSignedOrReviewed({
        eventId: event.id,
        eventDocumentIds,
      })
      goToNextStep()
    } catch (e) {
      Sentry.captureException(e)
      showErrorToast({
        description: 'Error signing documents. Please try again.',
      })
    }
  }

  const recipientWallet = eventUser.beneficiary_wallet ?? address!
  const isSignatureRequired =
    eventDocuments?.some((doc) => doc.requiresSignature) ?? false

  return (
    <AnimatedStepper
      step={step}
      nextHidden={nextHidden}
      onNext={goToNextStep}
      onPrevious={goToPreviousStep}
      isNextDisabled={isNextDisabled}
      startsAtIndex={startsAtIndex}
      showNavigationButtons={false}
    >
      {isDocumentActionRequired &&
        eventDocuments !== null &&
        eventDocuments.length > 0 &&
        eventUserDocumentsStatus !== null && (
          <ClaimDocumentsStep
            eventDocuments={eventDocuments}
            distributor={distributor}
            allocationAmount={{
              value: merkleLeaf.amount.div(10 ** distributor.token.decimals),
              symbol: token.symbol,
            }}
            vestingSchedule={merkleLeaf.vestingSchedule}
            beneficiaryWallet={recipientWallet}
            ambassadorName={userFullName}
            isPending={isPendingPostDocumentSignOrAgree}
            onSubmit={handleDocumentSigning}
            isSignatureRequired={isSignatureRequired}
          />
        )}
      <ClaimStep
        event={event}
        distributor={distributor}
        chainId={chainId}
        distributorAddress={distributorAddress}
        merkleLeaf={merkleLeaf}
        token={token}
        claimAmount={claimAmount}
        onClaimSuccess={onClaimSuccess}
        onFinished={onFinished}
      />
    </AnimatedStepper>
  )
}
